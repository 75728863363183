
import ModalForm from "../Blog/ModalForm.vue";

export default {
  props: {
    pipedrive_link: {
      type: String,
    },
  },
  methods: {
    openForm() {
      this.$modal.open({
        id: "ModalForm",
        component: ModalForm,
        data: {
          props: {
            book: this.last,
            pipedrive_link: this.pipedrive_link,
          },
        },
      });
    },
  },
};
