
export default {
  name: "ModalForm",
  props: {
    book: {
      type: Object,
    },
    pipedrive_link: {
      type: String,
    },
  },
  head: {
    script: [
      {
        src: "https://webforms.pipedrive.com/f/loader",
      },
    ],
  },
  /*
  data() {
    return {
      form: {
        name: "",
        lastName: "",
        email: "",
        phoneNumber: "",
      },
      pipedrive_url: "https://webforms.pipedrive.com/f/loader",
    };
  },
  methods: {
    async onSubmit() {
      const { name, lastName, email, phoneNumber } = this.form;
      const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

      email.match(regex)
        ? name && lastName && phoneNumber
          ? (await this.$strapi.create("downloaders", {
              name: name,
              lastName: lastName,
              email: email,
              phoneNumber: phoneNumber,
              livres: [this.book.id],
            }),
            window.open(this.book.pdf[0].url, "_blank"))
          : alert("Veuillez remplir tous les champs")
        : alert("Veuillez entrer une adresse email valide");
    },
  }, */
};
